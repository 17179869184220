.logout-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
}

.logout-wrapper {
  width: 30px;
  height: 30px;
  background-color: #139ef1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.project-name {
  width: 320px;
  color: gray;
  overflow: auto;
}


/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  width: 3px;
  /* Adjust the width to your preference */
  height: 3px;
  /* Adjust the height for horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
